<template>
  <div class="timeMeasurement">
    <LoadingPlaceholder v-if="preparing == true" />

    <SessionOverview
      v-show="preparing == false"
      v-if="photos != null && filter.startDate != null"
      :runs="photos"
      :display-properties="['duration', 'createDate', 'card']"
      :loading="loading"
      :filter="filter"
      :installation-type="installationType"
      :installation-id="installationId"
      @loadSessions="loadSessions"
    />

    <SweetModal
      :id="modalId"
      ref="timeMeasurementModal"
      title="Session"
      width="75%"
      @closeModal="closeModal"
    >
      <img
        v-if="altImageAvailable"
        id="img"
        :src="altImageUrl"
        class="img-fluid"
      >
      <ImagePlaceholderSvg
        v-else
        class="img-fluid"
      />
    </SweetModal>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'TimeMeasurement',
  components: {
    ImagePlaceholderSvg: () => import('@/components/Base/ImagePlaceholderSvg.vue'),
    SessionOverview: () => import('@/components/Media/SessionOverview.vue'),
    SweetModal
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    installationType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      useAlternativeFilter: true,
      loading: true,
      photos: null,
      altImageUrl: "",
      modalId: 'timeMeasurementModal',
      filter: {
        dates: null,
        cardNumber: null,
        startDate: null,
        endDate: null,
        showIncomplete: true,
        limit: 100
      },
      preparing: true
    }
  },
  computed: {
    areTherePhotos: function () {
      if (this.photos && this.photos.length > 0) return true;
      return false;
    },
    altImageAvailable: function () {
      if (this.altImageUrl && this.altImageUrl.length > 0) {
        return true;
      }
      return false;
    }
  },
  created () {
    let tmpDate = new Date(Date.now());
    this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 0, 0, 0);
    this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
    this.filter.dates = [];
    this.filter.dates.push(this.filter.startDate, this.filter.endDate);

    this.loadSessions();
    this.$eventBus.$on(`openModal${this.modalId}`, this.openModal);
  },
  beforeDestroy () {
    this.$eventBus.$off(`openModal${this.modalId}`, this.openModal);
  },
  methods: {
    openModal (payload) {
      if (payload) this.altImageUrl = payload.imageUrl;
      this.$refs.timeMeasurementModal.open();
    },
    closeModal () {
      this.altImageUrl = '';
      this.$refs.timeMeasurementModal.close();
    },
    // GET DEVICE gets the data for the device specified by the deviceId
    loadSessions (payload) {
      if (payload == null || this.useAlternativeFilter == true) {
        payload = {
          limit: 100,
          dates: [this.useAlternativeFilter == true ? null : this.filter.startDate, this.useAlternativeFilter == true ? null : this.filter.endDate]
        }
      }
      this.loading = true;
      this.axios.get(`/Media/GetTimeMeasurement/${ this.installationId }/${ payload.limit }/${ this.dateTime_isoString(payload.dates[0]) }/${ this.dateTime_isoString(payload.dates[1]) }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.photos = response.data;
        })
        .finally(() => {
          this.loading = false;
          window.setTimeout(() => {
            this.preparing = false;
          }, 500);
        });
    }
  }
}
</script>